<template>
  <div>
    <div v-if="verify">
      <div>截止{{ formattedDate }}嘉宾报名数据：</div>
      <div>岳麓论坛：{{ totalData[totalKey.key6] }}</div>
      <div>峰会新十年 湘商新征程：{{ totalData[totalKey.key1] }}</div>
      <div>文化智核 科技新篇：{{ totalData[totalKey.key2] }}</div>
      <div>投资新趋势 产业新格局：{{ totalData[totalKey.key3] }}</div>
      <div>产业互联网 智汇长沙城：{{ totalData[totalKey.key4] }}</div>
      <div>AI驱动 数实融合：{{ totalData[totalKey.key5] }}</div>
      <div>总数：{{ totalData[totalKey.total] }}</div>
      <van-button round type="info" @click="copyTotalContext">复制</van-button>
      <br />
      <hr />
      <br />
      <div>截止{{ formattedDate }}嘉宾审核数据：</div>
      <div>
        岳麓论坛：（已审核{{ totalData.successCounts[totalKey.key6] }}/{{
          totalData[totalKey.key6]
        }}）
      </div>
      <div>
        峰会新十年 湘商新征程：（已审核{{
          totalData.successCounts[totalKey.key1]
        }}/{{ totalData[totalKey.key1] }}）
      </div>
      <div>
        文化智核 科技新篇：（已审核{{
          totalData.successCounts[totalKey.key2]
        }}/{{ totalData[totalKey.key2] }}）
      </div>
      <div>
        投资新趋势 产业新格局：（已审核{{
          totalData.successCounts[totalKey.key3]
        }}/{{ totalData[totalKey.key3] }}）
      </div>
      <div>
        产业互联网 智汇长沙城：（已审核{{
          totalData.successCounts[totalKey.key4]
        }}/{{ totalData[totalKey.key4] }}）
      </div>
      <div>
        AI驱动 数实融合：（已审核{{ totalData.successCounts[totalKey.key5] }}/{{
          totalData[totalKey.key5]
        }}）
      </div>
      <div>总数：{{ totalData[totalKey.total] }}</div>
      <van-button round type="info" @click="copyAuditContext">复制</van-button>
      <br />
      <hr />
      <br />
      <div>截止{{ formattedDate }}工作人员报名数据：</div>
      <div>岳麓论坛：{{ workTotalData[totalKey.key6] }}</div>
      <div>峰会新十年 湘商新征程：{{ workTotalData[totalKey.key1] }}</div>
      <div>文化智核 科技新篇：{{ workTotalData[totalKey.key2] }}</div>
      <div>投资新趋势 产业新格局：{{ workTotalData[totalKey.key3] }}</div>
      <div>产业互联网 智汇长沙城：{{ workTotalData[totalKey.key4] }}</div>
      <div>AI驱动 数实融合：{{ workTotalData[totalKey.key5] }}</div>
      <div>总数：{{ workTotalData[totalKey.total] }}</div>
      <van-button round type="info" @click="copyWorkTotalContext"
        >复制</van-button
      >
    </div>
    <div v-else>
      <div>输入密码查看</div>
      <el-input v-model="password" placeholder="请输入内容"></el-input>
      <van-button round type="info" @click="verifyPassword">确定</van-button>
    </div>
  </div>
</template>

<script>
import { basicRequest } from "@/utils/http";
import { Toast } from "vant";
export default {
  data() {
    return {
      verify: false,
      password: "",
      totalKey: {
        key1: "54a076975bdb4e4e8d8ccdb00e5e133f",
        key2: "35ca9c7f22614d8e82cc791d83436b7d",
        key3: "525afbdaac6c4478b8c6f1391d453aca",
        key4: "8355b261ea5a4559ab727ba647814891",
        key5: "84fcd5b7c57f43208c05c68f5f1ede6d",
        key6: "7c6a4c2e9e87471e9eea4e3f68bd8c64",
        total: "allTotal",
      },
      totalData: {},
      workTotalData: {},
      currentTime: "",
    };
  },
  mounted() {
    // this.$http.post('/api/backstageApply/getPageList',{miceLink:'0f9991f1f7f446ffbd5c12eaa4636122'})
    // axios.post('http://ylfh.admin.huiyipro.com/api/api/backstageApply/getPageList')
  },
  methods: {
    verifyPassword() {
      if (this.password !== "2024") {
        console.log("密码错误");
        return;
      }
      this.verify = true;
      this.getTotalData();
      this.getWorkTotalData();
    },
    getTotalData() {
      this.$http.get("/api/backstageApply/statistics").then((res) => {
        this.formattedDate = this.$moment().format("YYYY-MM-DD HH:mm");
        this.totalData = res;
      });
    },
    getWorkTotalData() {
      this.$http.get("/api/backstageApply/statistics?type=1").then((res) => {
        this.workTotalData = res;
      });
    },
    async copyTotalContext() {
      const text = `截止${this.formattedDate}嘉宾报名数据：\n岳麓论坛： ${
        this.totalData[this.totalKey.key6]
      }\n峰会新十年 湘商新征程：${
        this.totalData[this.totalKey.key1]
      }\n文化智核 科技新篇：${
        this.totalData[this.totalKey.key2]
      }\n投资新趋势 产业新格局：${
        this.totalData[this.totalKey.key3]
      }\n产业互联网 智汇长沙城：${
        this.totalData[this.totalKey.key4]
      }\nAI驱动 数实融合：${this.totalData[this.totalKey.key5]}\n总数：${
        this.totalData[this.totalKey.total]
      }\n`;
      try {
        await navigator.clipboard.writeText(text);
        Toast("内容已复制到剪贴板！");
      } catch (err) {
        console.error("无法复制：", err);
      }
    },
    async copyWorkTotalContext() {
      const text = `截止${this.formattedDate}工作人员报名数据：\n岳麓论坛： ${
        this.workTotalData[this.totalKey.key6]
      }\n峰会新十年 湘商新征程：${
        this.workTotalData[this.totalKey.key1]
      }\n文化智核 科技新篇：${
        this.workTotalData[this.totalKey.key2]
      }\n投资新趋势 产业新格局：${
        this.workTotalData[this.totalKey.key3]
      }\n产业互联网 智汇长沙城：${
        this.workTotalData[this.totalKey.key4]
      }\nAI驱动 数实融合：${this.workTotalData[this.totalKey.key5]}\n总数：${
        this.workTotalData[this.totalKey.total]
      }\n`;
      try {
        await navigator.clipboard.writeText(text);
        Toast("内容已复制到剪贴板！");
      } catch (err) {
        console.error("无法复制：", err);
      }
    },
    async copyAuditContext() {
      const text = `截止${
        this.formattedDate
      }嘉宾审核数据：\n岳麓论坛：（已审核${
        this.totalData.successCounts[this.totalKey.key6]
      }/${
        this.totalData[this.totalKey.key6]
      } )\n峰会新十年 湘商新征程：（已审核${
        this.totalData.successCounts[this.totalKey.key1]
      }/${this.totalData[this.totalKey.key1]} )\n文化智核 科技新篇：（已审核${
        this.totalData.successCounts[this.totalKey.key2]
      }/${
        this.totalData[this.totalKey.key2]
      } )\n投资新趋势 产业新格局：（已审核${
        this.totalData.successCounts[this.totalKey.key3]
      }/${
        this.totalData[this.totalKey.key3]
      } )\n产业互联网 智汇长沙城：（已审核${
        this.totalData.successCounts[this.totalKey.key4]
      }/${this.totalData[this.totalKey.key4]} )\nAI驱动 数实融合：（已审核${
        this.totalData.successCounts[this.totalKey.key5]
      }/${this.totalData[this.totalKey.key5]} )\n总数：${
        this.totalData[this.totalKey.total]
      }\n`;
      try {
        await navigator.clipboard.writeText(text);
        Toast("内容已复制到剪贴板！");
      } catch (err) {
        console.error("无法复制：", err);
      }
    },
  },
};
</script>

<style>
</style>